import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from "./components/Header";
import Home from "./components/Home";

import './css/hover.min.css';
import './css/slicknav.min.css';
import './css/animate.min.css';
import './css/magnific-popup.css';
import './css/slick.css';
import './css/reset.css';
import './css/style.css';
import './css/responsive.css';
import './css/skin/blue.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <div className="content">
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
