import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import API from '../utils/api'
import {
  Logo,
  No1,
  No2,
  No3,
  Down,
  Center,
  LogoLeft,
  LogoRight,
  App1,
  App2,
  App3,
  App4,
  LogoFooter,
  dash01,
  report01,
  invoice01,
  users01,
  product01,
  purchase01,
  wa01,
  history01,
  pay01,
  pos01,
  order01,
  msite,
  android,
  howWorks,
  side01,
  side02,
  side03,
  side04,
  side00
} from '../assets'

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map(str => <p>{str}</p>);
}

const Home = () => {
  const [packages, setPackages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [activeFeature, setActiveFeature] = useState({});

  const dashboard_features = [
    {
      name: "Dashboard Statistik",
      active: true, image: dash01,
      desc: "Dashboard Statistik memperlihatkan statistik omset penjualan dari bulan ke bulan atau dalam hari bulan berjalan, disertai akumulasi jumlah pesanan, jumlah omset, jumlah pengeluaran dan profit."
    },
    {
      name: "Reporting Lengkap",
      active: false, image: report01,
      desc: "Report Lengkap memperlihatkan hasil pendapatan penjualan dalam setiap harinya. dalam satu bulan ataupun perkembangan pendapatan dari bulan ke bulan dalam 1 tahun."
    },
    {
      name: "Invoice Transaksi",
      active: false, image: invoice01,
      desc: "Invoice Transaksi memperlihatkan detail pesanan atau tagihan yang telah dibuat."
    },
    {
      name: "Manajemen Pengguna",
      active: false, image: users01,
      desc: "Manajemen Pengguna untuk mengelola data-data pengguna yang sudah didaftarkan, menambahkan pengguna baru, membuat atau mengelola level group pengguna seperti konsumen, reseller atau distributor bila diperlukan."
    },
    {
      name: "Manajemen POS",
      active: false, image: pos01,
      desc: "Point of Sales untuk melihat dan membuat pesanan baru atas nama pemesan yang dilakukan oleh staff, dengan cara memilih beberapa produk yang ingin dipesan, mengisi quantity pembelian, mengisi data pemesanan hingga menyelesaikan pesanannya."
    },
    {
      name: "Manajemen Pesanan",
      active: false, image: order01,
      desc: "Manajemen Pesanan untuk mengelola pesanan yang sudah dibuat, dilengkapi dengan fitur pencarian yang lengkap dan disertai dengan akumulasi nominal pesanan."
    },
    {
      name: "Manajemen Produk & Varian",
      active: false, image: product01,
      desc: "Manajemen Produk & Varian untuk mengelola menambahkan produk baru, menambahkan stok produk bisa secara varian ataupun sub varian, pengaturan dinamis penetapan harga berdasarkan varian ataupun level group pengguna yang berbeda-beda, menetapkan diskon, komisi, poin dll."
    },
    {
      name: "Manajemen Pengeluaran",
      active: false, image: pay01,
      desc: "Manajemen Pengeluaran untuk membuat segala bentuk report pengeluaran baru ataupun merubah atau mengelola data pengeluaran yang sudah dibuat, disertai akumulasi jumlah pengeluaran."
    },
    {
      name: "Manajemen Purchasing",
      active: false, image: purchase01,
      desc: "Manajemen Purchasing untuk membuat segala bentuk report pengadaan barang ataupun merubah atau mengelola data pengadaan barang yang sudah dibuat, disertai akumulasi jumlah pengadaan barang dan stok barang bisa langsung masuk dari sini."
    },
    {
      name: "Dinamis WA Notifikasi",
      active: false, image: wa01,
      desc: "Dinamis WA Notifikasi untuk membuat pesan WhatsApp otomatis yang akan dikirim setiap perubahan aktifitas pemesanan seperti otomatis kirim pesan saat melakukan pesanan baru, status pesanan brubah dikirim, terbayar atapun diterima."
    },
    {
      name: "Riwayat Transaksi",
      active: false, image: history01,
      desc: "Riwayat Transaksi akun-akun penerimaan atau pengiriman pembayaran, lengkap dengan akumulasi nominal terbaru dan riwayat uang masuk ataupun uang keluar."
    }
  ]

  const onClickFeature = (name) => {
    const oldData = dashboard_features;
    const new_data = oldData.findIndex((od) => od.name === name)
    if (new_data !== -1){
      setActiveFeature(oldData[new_data]);
    }
  }

  const loadCompanies = () => {
    const config = { headers: { Authorization: `Bearer` }, params: { pagination: false} };
    API.get(`get_companies`, config)
      .then(res => {
        const data = res.data.data;
        setCompanies(data)
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer` }, params: { pagination: false} };
    API.get(`packages`, config)
      .then(res => {
        const data = res.data.data;
        setPackages(data)
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  useEffect(() => {
    loadData();
    setFeatures(dashboard_features);
    setActiveFeature(dashboard_features[0]);
    loadCompanies();
  },[])

  return (
    <div>
      {/* Start Header */}
      <header className="header clearfix" id="header">
        {/* Header Inner */}
        <div className="header-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-3 col-xs-12">
                {/*Logo*/}
                <div className="logo">
                  <a className="index.html" href="">
                    <img src={Logo} alt="true" />
                  </a>
                </div>
                {/*/End Logo*/}
                {/*Mobile Menu*/}
                <div className="mobile-menu" />
                {/*/ End Mobile Menu*/}
              </div>
              <div className="col-md-9 col-sm-9 col-xs-12">
                {/*Main Menu*/}
                <div className="main-menu">
                  {/*/ End Cart Bar*/}
                  {/*Navigation*/}
                  <nav className="navbar">
                    <div className="collapse navbar-collapse">
                      <ul className="nav menu navbar-nav">
                        <li className="current"><a href="#header">Home</a></li>
                        <li><a href="#why-us">Why Us</a></li>
                        <li><a href="#feature">Fitur</a></li>
                        <li><a href="#price">Paket Harga</a></li>
                        <li><a href="#client">Klien</a></li>
                        <li><a href="#contact">Kontak</a></li>
                        {/* <li style={{ background: `#7d2ae7`, marginTop: `5px`}}><a href="/affiliate" style={{ padding: `10px 15px 10px 0px`, color: `#ffffff` }}>COBA SEKARANG</a></li> */}
                      </ul>
                    </div>
                  </nav>
                  {/*/ End Navigation*/}
                </div>
                {/*/ End Main Menu*/}
              </div>
            </div>
          </div>
        </div>
        {/*/ End Header Inner*/}
      </header>
      {/*/ End Header*/}

      <section id="app-features" className="supia-app-main">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.6s">
              {/* App Image */}
              <img src={Down} alt />
              {/*/ End App Image */}
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.6s">
              {/* App Text */}
              <div className="app-text">
                <h1>Aplikasi Toko Online Untuk Brandmu <span className="typed2" /></h1>
                <p>
                  1.Aplikasi android & IOS untuk bisnis atau brandmu<br/>
                  2.CRM Dashboard yang canggih dilengkapi dengan fitur-fitur terbaik<br/>
                  3.Manajemen data konsumen, pemesanan, keuangan, pembukuan & operasional bisnis menjadi mudah & efisien<br/>
                  4.Kelola bisnis kapanpun & dimanapun<br/>
                  5.Sangat mudah digunakan<br/>
                </p>
                <div className="button">
                  <a href={`https://dashboard.aplikabizz.com/auth/register`} className="btn primary" target="_blank">
                    <i className="fa fa-play" />Coba Sekarang
                  </a>
                </div>
              </div>
              {/*/ End App Text */}
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="intro-homepage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Mengapa Pilih Aplikabizz.com</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.5s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={LogoLeft} alt="true" />
                </div>
                <div className="member-name">
                  <h4>APPS TOKO ONLINE</h4>
                  <p>Apps Android atau Ios Toko Online Yang Dibuat Sesuai Brand/Merek Perusahaan, Memudahkan Member Melakukan Pembelian ataupun Mengelola Pesanannya</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.3s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={Center} alt="true" />
                </div>
                <div className="member-name">
                  <h4>CRM DASHBOARD</h4>
                  <p>Dashboard Panel Dengan Fitur Yang Lengkap Membantu Mengelola Segala Aktifitas Penjualan, Pendapatan Sampai Pengeluaran</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12  wow fadeInUp" data-wow-delay="0.7s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={LogoRight} alt="true" />
                </div>
                <div className="member-name">
                  <h4>MOBILE WEB</h4>
                  <p>Mobile Web Toko Online Yang Dibuat Sesuai Brand/Merek Perusahaan, Memudahkan Member Melakukan Pembelian ataupun Mengelola Pesanannya</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
          </div>
        </div>
      </section>

      <section id="feature" className="app-features section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title">
                <h2>Fitur Unggulan Aplikabizz<br/>CRM Dashboard</h2>
                <ul className='menu-features'>
                  {
                    dashboard_features.map((f) => {
                      return(
                        <li onClick={(e) => onClickFeature(f.name)} className={activeFeature && f.name === activeFeature.name ? 'active' : ''}>{f.name}</li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          {
            dashboard_features.map((f) => {
              return(
                <div className={activeFeature && f.name === activeFeature.name ? 'row active' : 'row hide'}>
                  <div className="col-md-6 col-sm-6 col-xs-12" data-wow-delay="0.6s">
                    <img src={f.image} alt />
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12" data-wow-delay="0.6s">
                    <div className="app-text">
                      <h3 style={{marginTop: 60}}>{f.name}</h3>
                      <br/>
                      <p>
                        {f.desc}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>

      <section className="app-features section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Aplikasi Toko</h2>
                <p>Beberapa fitur utama dari Apps toko<br/> dengan brand merek perusahaan sendiri yang bakal anda dapatkan.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-dashboard" />
                    <h4>User Friendly</h4>
                    <p>Tampilan Apps <br/>Yang User Friendly & Mudah Digunakan</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-shopping-cart" />
                    <h4>Halaman Belanja</h4>
                    <p>Menampilkan Semua Produk<br/> Yang Dipilih Untuk Dibeli</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-list" />
                    <h4>List Pesanan</h4>
                    <p>Merincikan List & Detail Pesanan<br/>Yang Telah Dibuat</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* App Slide */}
              <div className="app-slide">
                <div className="single-slide">
                  <img src={App1} alt="#" />
                </div>
                <div className="single-slide">
                  <img src={App2} alt="#" />
                </div>
                <div className="single-slide">
                  <img src={App3} alt="#" />
                </div>
                <div className="single-slide">
                  <img src={App4} alt="#" />
                </div>
              </div>
              {/*/ End App Slide */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-search" />
                    <h4>Detail Produk</h4>
                    <p>Menampilkan Semua Produk<br/>Dan Detail Produk</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-line-chart" />
                    <h4>Analisis</h4>
                    <p>Berisikan Laporan Penjualan<br/>Yang Berhasil Dibuat</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-gift" />
                    <h4>Fitur Marketting</h4>
                    <p>Banyak Fitur Yang Ada Diantaranya<br/> Cashback, Poin, Wishlist, Saldo Akun Dll.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="price" className="features section back-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Paket Layanan Tersedia</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {
              packages.map((p) => {
                return(
                  <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="single-news">
                      <div className="news-content">
                        <div className='package-title'>
                          {p.title}
                          {
                            p.price > 0 ? (
                              <>
                                <div className='package-price-stroke' style={{marginTop: 10, marginBottom:5}}>Rp.{numberFormat(p.price)}/bulan</div>
                                <div className='package-price' style={{marginTop: 0}}>Rp.{numberFormat(p.price-(p.price*p.discount/100))}/bulan <label className='disc-label'>Hemat {p.discount}%</label></div>
                              </>
                            ) : (
                              <div className='package-price' style={{marginTop: 10}}>GRATIS 1 BULAN</div>
                            )
                          }
                        </div>
                        <div className='package-feature'>
                          <NewlineText text={p.description} />
                        </div>
                        <div>
                          <hr/>
                          {p.can_multi_variant ? (<div className='package-feature'><p><i className="fa fa-check"></i> Multi Varian Produk & Varian Harga</p></div>) : null}
                          {p.can_promo ? (<div className='package-feature'><p><i className="fa fa-check"></i> Flash Sale Produk</p></div>) : null}
                          {p.can_subsidi ? (<div className='package-feature'><p><i className="fa fa-check"></i> Subsidi Produk</p></div>) : null}
                          {p.can_app_pos ? (<div className='package-feature'><p><i className="fa fa-check"></i> POS Pemesanan</p></div>) : null}
                          {p.can_voucher ? (<div className='package-feature'><p><i className="fa fa-check"></i> Voucher Belanja Produk</p></div>) : null}
                          {p.can_report ? (<div className='package-feature'><p><i className="fa fa-check"></i> Multi Report Kategori Penjualan</p></div>) : null}
                          {p.can_virtual_money ? (<div className='package-feature'><p><i className="fa fa-check"></i> Virtual Money Aplikasi</p></div>) : null}
                          {p.can_virtual_point ? (<div className='package-feature'><p><i className="fa fa-check"></i> Virtual Poin Aplikasi</p></div>) : null}
                          {p.can_cashback ? (<div className='package-feature'><p><i className="fa fa-check"></i> Cashback Produk</p></div>) : null}
                          {p.can_group_user ? (<div className='package-feature'><p><i className="fa fa-check"></i> Dinamis Group User & Group Harga</p></div>) : null}
                          {p.can_wa_notif ? (<div className='package-feature'><p><i className="fa fa-check"></i> Notifikasi WhatsApp Otomatis</p></div>) : null}
                          {p.can_referral ? (<div className='package-feature'><p><i className="fa fa-check"></i> Referral Member</p></div>) : null}
                          {p.can_app_android ? (<div className='package-feature'><p><i className="fa fa-check"></i> Gratis Aplikasi Android Toko</p></div>) : null}
                          {p.can_app_ios ? (<div className='package-feature'><p><i className="fa fa-check"></i> Gratis Aplikasi IOS Toko</p></div>) : null}
                        </div>
                        <a href={`https://dashboard.aplikabizz.com/auth/register`} className="btn btn-lg btn-info btn-chat" target="_blank" style={{lineHeight:'30px'}}>
                          <i className="fa fa-whatsapp my-float" /> DAFTAR SEKARANG
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>

      <section id="client" className="intro-homepage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Klien Kami</h2>
              </div>
            </div>
          </div>
          <div className="row">
            { companies.map((c) => {
              return(
                <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="single-team" style={{marginBottom:20, paddingTop:20}}>
                    <div className="team-head">
                      <img src={c.company_icon_url} alt="true" style={{height:50}}/>
                    </div>
                    {/* <div className="member-name">
                      <h4>{c.company_name}</h4>
                      <p>Apps Android atau Ios Toko Online Yang Dibuat Sesuai Brand/Merek Perusahaan, Memudahkan Member Melakukan Pembelian ataupun Mengelola Pesanannya</p>
                    </div> */}
                  </div>
                  {/*/ End Team */}
                </div>
              )
            })}
          </div>
          <div className="row" style={{paddingTop: 100}}>
            <div class="section-title block-div">
              <p>Salah satu toko gadget yang telah merilis 
              aplikasi di playstore untuk perusahaan gadgetstore sukabumi. dengan tujuan mempermudah pengelolaan data pemesanan konsumen, kelola membership dan menaikan branding perusahaan.</p>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="single-team" style={{marginBottom:20, paddingTop:20}}>
                <img src={side00} alt="true"/>
              </div>
            </div>
            <div class="section-title block-div" style={{paddingTop: 100}}>
              <p>Salah satu perusahaan lain menggunakan jasa aplikabizz untuk membuat aplikasi reseller yaitu seemitra dan andromeda apps.
                memudahkan para mitra nya melakukan pengelolaan untuk pesanan konsumen nya. </p>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="0.5s">
              <div className="single-team" style={{marginBottom:20, paddingTop:20}}>
                <img src={side01} alt="true"/>
                <img src={side02} alt="true"/>
              </div>
            </div>
            <div class="section-title block-div" style={{paddingTop: 100}}>
              <p>Beberapa client yang bergerak di bisnis F&B
              yang telah menggunakan jasa aplikabizz merilis aplikasi untuk memudahkan konsumen memesan dan mendapatkan promo khusus. </p>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="0.5s">
              <div className="single-team" style={{marginBottom:20, paddingTop:20}}>
                <div className="team-head">
                 <img src={side03} alt="true"/>
                  <img src={side04} alt="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how-works" className="intro-homepage back-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Cara Kerja</h2>
                <br/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.6s">
              <div className="app-text">
                <ul className='ul-number'>
                  <li>Silahkan lihat dan tentukan pilihan layanan yang telah disediakan sesuai fitur dan kebutuhan perusahaan anda. aplikasi brandmu, dashboard, email perusahaan berikut domain perusahaan akan diproses setup setelah pembayaran.</li>
                  <li>Dashboard dan Website toko onlinemu akan aktif dalam waktu 2 x 24 jam tentunya dengan diakses langsung ke domain perusahaan yang diinginkan. Apps akan tersedia di play store  setelah proses verifikasi dari tim google selesai, biasanya memerlukan waktu 3 - 7 hari kerja untuk publish aplikasi baru.</li>
                  <li>Billing start period akan dihitung dari mulai aplikasi sudah terpublish di play store. Semua sistem digital perusahaan anda telah selesai di setup dan siap digunakan. Nikmati segala kemudahannya.</li>
                </ul>
                <br/>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.6s">
              <img src={howWorks} alt style={{width:'85%', margin: 'auto', display: 'block'}}/>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" id="contact">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Single Widget */}
              <div className="col-md-4 col-sm-4 col-xs-12 ">
                <div className="single-widget about">
                  <div className="footer-logo">
                    <a href="#"><img src={LogoFooter} alt="#" /></a>
                  </div>
                  <p>aplikabizz.com adalah sebuah layanan penyedia pembuatan aplikasi toko online beserta CRM di dalamnya untuk kembangkan bisnis secara online</p>
                  {/* <div className="button">
                    <a href="#" className="btn primary">About Us</a>
                  </div> */}
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-5 col-sm-5 col-xs-12">
                <div className="single-widget contact">
                  <h2>Kontak Kami</h2>
                  <ul className="list">
                    <li><i className="fa fa-home" />Kantor : aplikabizz.com Office, Jl.Pelabuan 2 km.07 Lembursitu, Kodepos 43169 Kota Sukabumi</li>
                    <li><i className="fa fa-phone" />Telp / WA : 0857-7795-6729 </li>
                    <li><i className="fa fa-envelope" />Email : <a href="mailto:onlinestore.web.id@gmail.com">onlinestore.web.id@gmail.com</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-widget">
                  <h2>Ikuti kami di</h2>
                  <ul className="social-icon-footer">
                    <li className="active"><a href="https://www.facebook.com/profile.php?id=61561885929928" target="_blank" ><i className="fa fa-facebook" />Facebook</a></li>
                    <li className="active"><a href="https://www.tiktok.com/@aplikabizz" target="_blank" ><i className="fa fa-tumblr" />Tiktok</a></li>
                    <li className="active"><a href="https://www.instagram.com/aplikabizz.official" target="_blank" ><i className="fa fa-instagram" />Instagram</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                {/* Copyright */}
                <p>Ⓒ Copyright aplikabizz.com {(new Date().getFullYear())}</p>
                {/*/ End Copyright */}
              </div>
            </div>
          </div>
        </div>

        {/*/ End Footer Bottom */}
      </footer>

      <a href={`https://api.whatsapp.com/send?phone=6285777956729&text=Hallo%21%20Saya%20Minat%20Coba%20Paket%20aplikabizz.com`} className="float" target="_blank">
        <i className="fa fa-whatsapp my-float" />
      </a>
    </div>
  )
}

export default Home
